import React from 'react';
import Youtube from 'react-youtube';
import LazyLoad from 'react-lazyload';
import './style.scss';



const CatoYoutube = ({videoId, opts, height, width, autoplay}) => {

    const playerVars = {
        autoplay: autoplay ? 1 : 0,
        rel: 0
    }

    return (
        <LazyLoad height={200}>
            <Youtube
                className="cato-youtube"
                videoId={videoId}
                opts={{
                    ...opts,
                    playerVars: {...playerVars}
                }}
            />
        </LazyLoad>
    )
}

CatoYoutube.defaultProps = {
    autoplay: true,
}

export default CatoYoutube;