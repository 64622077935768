import React from 'react';
import {Link} from 'gatsby';
import CatoButton from '../cato-button';
import './style.scss';

const ResourceLink = (props) => {
    const inner = <div className={`resource-link ${props.sideAlign ? 'side-align' : ''}`}>
        <div className="image-wrapper">
            {props.img}
        </div>
        <div className="text-wrapper">
            {/*{props.bold && <h3 dangerouslySetInnerHTML={{__html: props.bold}}/>}*/}
            <p dangerouslySetInnerHTML={{__html: props.light}}/>
            {props.type === 'multi' ?
                <span className="label">{props.buttonLabel}</span>
                :
                <CatoButton onClick={()=>{}} type="button" label={props.buttonLabel} className="green oval mid"/>
            }
        </div>
    </div>;

    const handleClick = (e) => {
        e.preventDefault();
        props.onClick(props.video);
    }

    return props.link.ext
        ? <a className={`resource-link ${props.type}`} onClick={props.video ? handleClick : null} href={props.link.ext} target="_blank" rel="noopener noreferrer">{inner}</a>
        : <Link className={`resource-link ${props.type}`} onClick={props.video ? handleClick : null} to={props.link.path}>{inner}</Link>;
};

export default ResourceLink