import React from 'react';
// import Img from 'gatsby-image/withIEPolyfill';
import Img from "gatsby-image" //<-- regular

const CatoImg = ({img, style, className="", id="", loading, isPublicFile, critical, sourcesType, onClick=null, width=null, height=null, fadeIn=null}) => {

    if(!img) return null;

    if(isPublicFile || (img.localFile && img.localFile.childImageSharp) || (img.childImageSharp) || sourcesType){

        if(Array.isArray(img)) {
            return (
                <Img
                    fluid={sourcesType === 'fluid' ? img : null}
                    fixed={sourcesType === 'fixed' ? img : null}
                    sizes={sourcesType === 'sizes' ? img : null}
                    style={style}
                    className={`cato-image ${className}`}
                    loading={loading}
                    alt={img.altText || ""}
                    onClick={onClick}
                />
            )
        }

        const childImage = img.childImageSharp || img.localFile.childImageSharp;

        return <Img
            fluid={childImage.fluid || null}
            fixed={childImage.fixed || null}
            sizes={childImage.sizes || null}
            style={style}
            className={`cato-image ${className}`}
            loading={loading}
            alt={img.altText || ""}
            onClick={onClick}
            fadeIn={fadeIn}
        />
    }

        const imgSrc = img.localFile ? img.localFile.publicURL : img.publicURL ? img.publicURL : img.sourceUrl ? img.sourceUrl : img;

        return <img src={imgSrc} className={`cato-image ${className}`} alt={img.altText || ""} width={width} height={height} style={style} id={id} onClick={onClick} onKeyDown={onClick} />;

}


CatoImg.defaultProps = {
    loading: 'lazy',
    id: '',
    className: '',
    isPublicFile: false,
    sourceType: null
}

export default CatoImg;