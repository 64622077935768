import React from 'react';
import {Link} from 'gatsby';


const CatoLink = ({path, className="", children, icon, style, onMouseEnter, onMouseLeave, onClick, ext=false, setHTML=null}) => {

    const pathIncludesCatoDomain = path && path.includes('https://www.catonetworks.com');

    const pathToUse = pathIncludesCatoDomain ? path.replace(/https?:\/\/[^\/]+/i, "") : path;

    const isLocalPath = pathToUse ? pathToUse[0] === '/' : null;

    const extLink = !isLocalPath || ext;

    const handleMouseEnter = (e) => onMouseEnter();

    const handleMouseLeave = (e) => onMouseLeave();

    const handleOnClick = (e) => onClick();

    return (
        extLink ?
            <a
                href={pathToUse}
                className={`cato-link ${className}`}
                target="_blank"
                rel="noopener noreferrer"
                style={style}
                onClick={onClick ? handleOnClick : null}
                onMouseEnter={onMouseEnter ? handleMouseEnter : null}
                onMouseLeave={onMouseLeave ? handleMouseLeave : null}
                dangerouslySetInnerHTML={setHTML ? {__html: setHTML} : null}
            >{children}</a>
            :
            <Link
                to={pathToUse}
                className={`cato-link ${className}`}
                style={style}
                onClick={onClick ? handleOnClick : null}
                onMouseEnter={onMouseEnter ? handleMouseEnter : null}
                onMouseLeave={onMouseLeave ? handleMouseLeave : null}
                dangerouslySetInnerHTML={setHTML ? {__html: setHTML} : null}
            >{children}</Link>

    )
};

export default CatoLink;
