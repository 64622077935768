import React, {Component, Fragment} from 'react';
import './style.scss';
import CatoButton from "../cato-button";
import {Link} from 'gatsby';
import {SocialLinks} from "../footer";
import {LANG, CONTACT_MAP} from '../../common/consts.js';


const PlusCheckbox = ({id,  className}) => (
    <Fragment>
        <input id={id}
               style={className === 'plus-checkbox' ? {display: 'none'} : {display: 'none', pointerEvents: 'none'}}
               type={'checkbox'} />
        <label
            htmlFor={id}
            style={className !== 'plus-checkbox' ? {pointerEvents: 'none'} : null}
            className={className}/>
    </Fragment>)

const LinkList = ({col,id, closeMenu}) => {

    if(col.title === 'Contact') return null;
    if(col.title === 'Follow Us') return (
        <div className={'bunch-links'}>
            <label className={'h1'} htmlFor={`PlusCheckbox-${id}`}>{col.title}</label>
            <SocialLinks />
        </div>
    );

    const submenu = col.subMenu ? col.subMenu[0].items ? col.subMenu.reduce((acc, curr) => {return [...acc, ...curr.items]} ,[]) : col.subMenu : null

    return (
        <div className={'bunch-links'}>
            <Link className={'h1'} to={col.path} onClick={closeMenu}>{col.title}</Link>
            <PlusCheckbox id={`PlusCheckbox-${id}`} className={submenu ?  'plus-checkbox' : 'chevron'} />
            {submenu &&
                <ul className={'hide-bullets-style'}>
                    {submenu.map((v, k) => <li key={k} className={`${v.klass}`}><Link to={v.path} onClick={closeMenu}>{v.title}</Link></li>)}
                </ul>
            }
        </div>
    )
};

class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    onClick = ({label, path}) => this.props.onContactClick({label, path});

    render() {
        const {items, lang} = this.props;
        return (
            <div className={'mobile-menu'}>
                <div className={'top-section'}>
                    <a href="tel:+16469759243"><h1>+1-646-975-9243</h1></a>
                    <CatoButton onClick={this.onClick} label={CONTACT_MAP[lang].label} className={`orange header`} path={CONTACT_MAP[lang].path}/>
                </div>
                <div className={'links-list'}>
                    {items.map((col, k) => <LinkList col={col} key={k} id={k} closeMenu={this.props.closeMenu}/>)}
                </div>
            </div>
        )
    }
}

export default MobileMenu