/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import StructuredData from './structured-data';
// import {XmlEntities} from 'html-entities';
import {htmlDecode} from '../common/utils';
// import {unescape} from 'lodash';
import {useStaticQuery, graphql} from "gatsby"
// import Logo from '../assets/images/'

// const entities = new XmlEntities();
// const htmlDecode = input => entities.decode(input);

function SEO({description, lang, meta, keywords, title, yoastData, seoTitle, seoPath, breadcrumbs, translations}) {
    const {site, metaImg} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        metaImg: file(relativePath: {eq: "cato green.png"}) {
            publicURL
        }
      }
    `
    )

    const prodDomain = 'https://www.catonetworks.com';
    // const titleTag = seoTitle ? seoTitle : `${title} | ${site.siteMetadata.title}`;
    const metaDescription = yoastData && yoastData.metaDesc ? yoastData.metaDesc : site.siteMetadata.description;
    const ogMetaDesc = yoastData && yoastData.opengraphDescription ? yoastData.opengraphDescription : metaDescription;
    const twitterDesc = yoastData && yoastData.twitterDescription ? yoastData.twitterDescription : ogMetaDesc;
    const ogTitle = yoastData && yoastData.opengraphTitle ? yoastData.opengraphTitle : title;
    const twitterTitle = yoastData && yoastData.twitterTitle ? yoastData.twitterTitle : ogTitle;
    const ogImg = yoastData && yoastData.opengraphImage ? `${yoastData.opengraphImage.sourceUrl}` : `${prodDomain}${metaImg.publicURL}`;
    const twitterImg = yoastData && yoastData.twitterImage ? yoastData.twitterImage.sourceUrl : ogImg;
    const canonical = yoastData && yoastData.canonical
        ?
        yoastData.canonical.includes(prodDomain)
            ?
            yoastData.canonical
                :
            `${prodDomain}${yoastData.canonical}`
        :
        `${prodDomain}${seoPath}`;

    const getXDefault = translations && translations.length ? translations.find(trans => trans.language.slug === 'en') : null;

    const translationsArray = translations && translations.length ? [...translations.map(trans => ({
        rel: 'alternate',
        hreflang: trans.language.slug,
        href: `${prodDomain}${trans.path}`
    })), {rel: 'alternate', hreflang: 'x-default', href: `${prodDomain}${getXDefault ? getXDefault.path : ''}`}] : [];

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={htmlDecode(title)}
            titleTemplate={!seoTitle ? `%s | ${site.siteMetadata.title}` : `${htmlDecode(seoTitle)}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: ogTitle,
                },
                {
                    property: `og:url`,
                    content: canonical
                },
                {
                    property: `og:image`,
                    content: ogImg
                },
                {
                    property: `og:description`,
                    content: ogMetaDesc,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:image`,
                    content: twitterImg
                },
                {
                    name: `twitter:title`,
                    content: twitterTitle,
                },
                {
                    name: `twitter:description`,
                    content: twitterDesc,
                },
            ]
                .concat(
                    keywords.length > 0
                        ? {
                            name: `keywords`,
                            content: keywords.join(`, `),
                        }
                        : []
                )
                .concat(meta)}
            link={[
                {
                    rel: 'canonical',
                    href: canonical
                },
                ...translationsArray
            ]}
        >
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "${encodeURI(ogTitle)}",
                        "publisher": {
                            "@type": "Organization",
                            "name": "Cato Networks"
                        }
                    }`}
                </script>
            {
                breadcrumbs &&
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [${breadcrumbs.map((b, i) => (`{
                        "@type": "ListItem",
                        "position": ${i + 1},
                        "item":  {
                            "@id": "https://www.catonetworks.com${b.path}",
                            "name": "${b.title}"
                        }
                    }`))}]
                    }`}
                </script>

            }
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
}

export default SEO
