import {useEffect, useState} from 'react';
import {IS_SMALL_DESKTOP, IS_DESKTOP, IS_MOBILE, IS_TABLET} from "../common/consts";

const useDeviceDetect = () => {
    const [isMobile, setMobile] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false)
    const [isSmallDesktop, setSmallDesktop] = useState(false);
    const [isDesktop, setDesktop] = useState(false);


    useEffect(() => {
        if(IS_MOBILE){
            setMobile(true)
            setIsMobileDevice(true)
        }

        if (IS_TABLET) {
            setTablet(true);
            setIsMobileDevice(true)
        }

        if(IS_SMALL_DESKTOP) {
            setSmallDesktop(true);
        }

        if(IS_DESKTOP) {
            setDesktop(true)
        }

    }, [])

    return { isMobile, isTablet, isDesktop, isSmallDesktop, isMobileDevice }
}

export default useDeviceDetect;