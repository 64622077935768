import React, {Component} from 'react';
import Slider from 'react-slick';

class CatoSlider extends Component {

    constructor(props) {
        super(props);

        this.slider = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.pause && this.props.pause) {
            this.slider.current.slickPause();
        } else if (prevProps.pause && !this.props.pause ) {
            this.slider.current.slickPlay();
        }
    }

    render() {
        return (
            <Slider ref={this.slider} {...this.props} className={`cato-slider ${this.props.className}`} >
                {this.props.children}
            </Slider>
        )
    }
}

CatoSlider.defaultProps = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
    pause: false,
    swipe: true,
    autoplay: false,
    pauseOnHover: false,
    responsive: [{
        breakpoint: 767,
        settings: {
            // centerPadding: '0'
        }
    }]
}

export default CatoSlider;