import React, {useState} from 'react';
import CatoImg from '../cato-image';
import {StaticQuery, graphql} from 'gatsby';
import {LANG} from '../../common/consts';
import './style.scss';

const addSpanToLastWord = (text) => {
    const pieces = text.replace('<br />', '<br/>').replace(/\n/ig, ' ').split(' ');
    const notLast = pieces.slice(0, -1).join(' ');
    return `${notLast} <span>${pieces[pieces.length -1]}</span>`
}

const GARTNER_CMS_MAP = {
    [LANG.ENG]: 'cmsData',
    [LANG.FR]: 'frenchCmsData',
    [LANG.DE]: 'germanCmsData'
}

const GartnerStrip = props => {
    const [open, setOpen] = useState(false);

    return (
        <StaticQuery
            query={graphql`
                query GartnerStripQuery {
                    forWhite: file(relativePath: { eq: "components/gartner-strip/12x_G.png"}) {
                        childImageSharp {
                        fluid(maxWidth: 245, quality: 100){
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    forGreen: file(relativePath: { eq: "components/gartner-strip/12x.png"}) {
                        childImageSharp {
                        fluid(maxWidth: 245, quality: 100){
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    topAligned:  file(relativePath: { eq: "components/gartner-strip/12x.png"}) {
                        childImageSharp {
                        fluid(maxWidth: 153, quality: 100){
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    accordionMobile: file(relativePath: { eq: "components/gartner-strip/12x.png"}) {
                        childImageSharp {
                        fluid(maxWidth: 100, quality: 100){
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    cmsData: wpPage(databaseId: { eq: 12 }){
                        id
                        gartnerStrip {
                            gartnerDarkHeadline
                            gartnerHeadline
                            gartnerUnderNine
                            gartnerAccordionHeadline
                            gartnerCustomerImageText
                            gartnerListItems {
                                title
                                dateText
                            }
                            gartnerDisclaimer
                        }
                    }
                    frenchCmsData: wpPage(databaseId: { eq: 14353 }){
                        id
                        gartnerStrip {
                            gartnerDarkHeadline
                            gartnerHeadline
                            gartnerUnderNine
                            gartnerAccordionHeadline
                            gartnerCustomerImageText
                            gartnerListItems {
                                title
                                dateText
                            }
                            gartnerDisclaimer
                        }
                    }
                    germanCmsData: wpPage(databaseId: { eq: 14641 }){
                        id
                        gartnerStrip {
                            gartnerDarkHeadline
                            gartnerHeadline
                            gartnerUnderNine
                            gartnerAccordionHeadline
                            gartnerCustomerImageText
                            gartnerListItems {
                                title
                                dateText
                            }
                            gartnerDisclaimer
                        }
                    }
                }
            `}
            render={data => {
                const gartnerLang = GARTNER_CMS_MAP[props.lang] ? GARTNER_CMS_MAP[props.lang] : GARTNER_CMS_MAP[LANG.ENG]

                const {[gartnerLang]: {gartnerStrip}} = data;

                const dark = props.klass === 'dark';

                const accordion = props.type === 'accordion';

                const numImg = accordion ? [data.accordionMobile.childImageSharp.fluid, {...data.topAligned.childImageSharp.fluid, media: `(min-width: 769px)`}] : props.klass === 'white' ? data.forWhite : data.forGreen;

                const greenThemeText = accordion ? <h2 className="text">{gartnerStrip.gartnerAccordionHeadline}</h2> : <div className="under-nine-text" dangerouslySetInnerHTML={{__html: addSpanToLastWord(gartnerStrip.gartnerUnderNine)}}/>

                return (
                    <section className={`gartner-strip ${props.klass} ${accordion ? 'accordion' : ''} ${open ? 'open' : ''}`}>
                        <div className="strip-top">
                            <div className="image-and-text-wrapper">
                                {!dark &&
                                <div className="image-wrapper">
                                    <CatoImg img={numImg} sourcesType={accordion ? 'fluid' : null}/>
                                </div>}
                                { !dark && greenThemeText}
                                {dark && <h2 dangerouslySetInnerHTML={{__html: gartnerStrip.gartnerDarkHeadline}}/>}
                            </div>
                            <div className={`text-wrapper ${open ? 'open' : ''}`}>
                                {
                                    gartnerStrip.gartnerHeadline && !accordion  && !dark &&
                                    <h2>{gartnerStrip.gartnerHeadline}</h2>
                                }
                                <ul>
                                    {gartnerStrip.gartnerListItems &&
                                    gartnerStrip.gartnerListItems.map((item, i) => (
                                        <li key={`gartner-item-${i}`}>
                                            {item.title && <div className="title" dangerouslySetInnerHTML={{__html:item.title}}/>}
                                        </li>
                                    ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className={`strip-bottom ${open ? 'open' : ''}`}>
                            {gartnerStrip.gartnerDisclaimer && <p>{gartnerStrip.gartnerDisclaimer}</p>}
                        </div>
                        <div className="open-arrow">
                            <div className={`arrow-wrap ${open ? 'open':''}`} onClick={()  => setOpen(!open)}/>
                        </div>
                    </section>
                )
            }}
        />
    )
}

GartnerStrip.defaultProps = {
    klass: 'green',
    accordion: false,
}

export default GartnerStrip;