import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import CatoYoutube from '../cato-youtube';
import Lightbox from '../lightbox';
import CatoImage from '../cato-image';
import CatoButton from '../cato-button';
import CatoSlider from '../slider';
import {IS_MOBILE, IS_TABLET, CURR_VID_INIT} from "../../common/consts"
import Shadow from './shadow.png';
import './style.scss';

export const VideoLightboxWrap = (props) => {
    const {currVid, active, setActive} = props;
    const {vidId, title, subTitle, path, buttonLabel} = currVid;

    const handleSetActive = () => props.setActive();

    return (
        <Lightbox active={props.active} setActive={handleSetActive} className={"videos"}>
            {
                title &&
                <div className="text-wrapper">
                    <h3>{title}</h3>
                    {subTitle && <h4>{subTitle}</h4>}
                </div>
            }

                {active ? <CatoYoutube videoId={vidId} autoplay={true}/> : null}
            {path &&
            <CatoButton
                onClick={setActive}
                path={path}
                label={buttonLabel}
            />}
        </Lightbox>
    )
}

const VideoImageText = (props) => {
     const {active, isMobile} = props;

    const title = props.title && !props.description ? <div className="title">{props.title}</div> : null;

    const subTitle = props.subTitle && !props.description ? <div className="sub-title">{props.subTitle}</div> : null;

    const button = props.isSlider ? <span className="cato-button green-bg">WATCH</span> : null;

    const onClick = () => {
        props.setCurrVid({
            vidId: props.vidId,
            title: props.title,
            subTitle: props.subTitle,
            description: props.description,
            path: props.path,
            buttonLabel: props.lightboxButtonLabel
        });
        props.toggleActive();
    };

    const vidDiv = props.withLightbox
    ?
        <div className="image-wrap">
            <CatoImage img={props.vidImg ? props.vidImg : `https://i.ytimg.com/vi/${props.vidId}/hqdefault.jpg`}/>
            {props.isSlider && props.includeShadow && <CatoImage img={Shadow} className="shadow"/>}
        </div>
    :
        <div className="image-wrap">
            {<CatoYoutube videoId={props.vidId} autoplay={false}/>}
        </div>

    return (
        <div className={`video-image-text-wrap box-wrap ${props.align === 'row' ? 'row' : ''} ${props.isSlider ? 'slider' : ''}`} onClick={onClick}>
            {vidDiv}
            {(props.showText || props.isSlider) &&
            <div className="text-wrapper">
                {isMobile && subTitle}
                {title}
                {!isMobile && subTitle}
                {button}
            </div>
            }
        </div>
    )
}

const VideoWithLightbox = (props) => {
    const [active, setActive] = useState(false);
    const [currVid, setCurrVid] = useState(CURR_VID_INIT);
    const [isMobile, setMobile] = useState(false);
    const [isTablet, setTablet] = useState(false);
    const [pristine, setPristine] = useState(true);

    useEffect(() => {
        if(IS_MOBILE || IS_TABLET) {
            setMobile(true);
        }

        if (IS_TABLET) {
            setTablet(true);
        }
    },[])

    const toggleActive = () => setActive(!active);

    const isSlider = props.items.length > 1;

    const withLightbox = (isSlider || isMobile || (props.withLightbox && props.withLightbox === true));

    const videoImageBoxes = props.items.map((item, i) => {
        return (
            <VideoImageText
                key={`video-image-text-${i}`}
                {...props}
                {...item}
                active={active}
                withLightbox={withLightbox}
                isSlider={props.items.length > 1}
                lightboxButtonLabel={item.lightboxButtonLabel ? item.lightboxButtonLabel : props.lightboxButtonLabel}
                setCurrVid={setCurrVid}
                isMobile={isMobile && !isTablet}
                toggleActive={toggleActive}/>
            )
        }
    )

    const handleSlide = () => {
        if(pristine) {
            setPristine(false);
        }
    };

    return (
        <div className={`video-with-lightbox ${isSlider ? 'slider' : ''} ${props.type}`}>
            {
                isSlider
                ?
                    <CatoSlider
                        slidesToShow={props.slidesToShow ? props.slidesToShow : isMobile ? 1 : 3}
                        variableWidth={true}
                        slidesToScroll={props.slidesToScroll ? props.slidesToScroll : isMobile ? 1 : 2}
                        dots={false}
                        afterChange={handleSlide}
                        centerMode={props.centerMode !== undefined ? props.centerMode : isMobile}
                        centerPadding={isMobile && !isTablet ? 0 : isTablet ? 20 : 50}
                        arrows={props.arrows ? props.arrows : !isMobile}
                        className={`${!pristine ? 'not-pristine' : ''}`}
                    >
                        {
                            !isMobile && props.includeSpacer ? videoImageBoxes.reduce((arr,b) => [...arr, b, props.spacer], []) : videoImageBoxes}
                    </CatoSlider>
                    :
                    videoImageBoxes
            }
            {withLightbox && <VideoLightboxWrap {...props} currVid={currVid} active={active} setActive={toggleActive}/>}
        </div>

    )
}

VideoWithLightbox.propTypes = {
    type: PropTypes.oneOf(['row', 'column'])
};

VideoWithLightbox.defaultProps = {
    isSingle: true,
    align: 'column',
    lightboxButtonLabel: 'READ THE FULL STORY',
    showText: false,
    withLightbox: false,
    spacer: <div key={`spacer`} className="spacer"/>,
    includeSpacer: true,
    includeShadow: true,
    type: 'row',
    playButtonOnHover: false
}

export default VideoWithLightbox;