import React from 'react';
import Helmet from 'react-helmet';
import {pickBy, identity} from 'lodash';
const StructuredData = ({data, type}) => {

    const fixAndStringifyData = () => {
        data["@context"] = "https://schema.org";
        data["@type"] = type;



        return JSON.stringify(pickBy(data, identity));
    }

    return  (
        <Helmet>
            <script type="application/ld+json">
                {fixAndStringifyData()}
            </script>
        </Helmet>
    )

}

export default StructuredData;