/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import {LANG} from '../common/consts';
import SEO from './seo';
import Helmet from 'react-helmet';
import Header from "./header";
import Footer from './footer';
import {VideoLightboxWrap} from './video-lightbox';
import "./layout.css"
import "../assets/styles/main.scss";
// import InsightsBanner from './insights-banner';
import GartnerStrip from './gartner-strip';
import useDeviceDetect from '../hooks/useDeviceDetect';
import {GlobalStateContext, GlobalDispatchContext} from "../context/GlobalContextProvider";
import {ACTION_TYPES, CURR_VID_INIT} from "../common/consts"
import loadable, {lazy} from '@loadable/component';

// const YouTubeSection = loadable(() => import("./youtube-section"), {ssr: false});
// const StickyBannerCTA = loadable(() => import("./sticky-banner-cta"));

const cleanImages = () => {
    if(typeof window === 'undefined') return;
    let imagesList = Array.from(document.querySelectorAll('img')).filter(i => i !== undefined && i.className !== undefined && (i.className.match(/wp-image/g) || i.getAttribute('src').match(/wp-content/) || []).length);
    console.log("Deleting img tag's height and width attrs: ",imagesList);
    imagesList.map(i => {
        const src = i.getAttribute('src');
        if(process.env.GATSBY_MODE === 'preview' && src && !src.includes('https://catonewwebsite.kinsta.cloud')) {
            i.setAttribute('src', `https://catonewwebsite.kinsta.cloud${src}`)
        }
        i.removeAttribute("width");
        i.removeAttribute("height");
        return i;
    });
};

let T_O = {};
const doCleanImages = () => {clearTimeout(T_O); T_O = setTimeout(cleanImages, 200);}

const menuMapping = {
    [LANG.ENG]: 'mainMenu',
    [LANG.JP]: 'japaneseMainMenu',
    [LANG.FR]: 'frenchMainMenu',
    [LANG.DE]: 'germanMainMenu'
}

const langClassMap = {
    [LANG.ENG]: 'en',
    [LANG.JP]: 'jp',
    [LANG.FR]: 'fr',
    [LANG.DE]: 'de'
}

const Layout = ({
                    wpid,
                    children,
                    className,
                    whiteMenu,
                    yoastData,
                    title,
                    seoTitle,
                    stickyBanner,
                    youtube,
                    coolInsights,
                    transparentMenu,
                    darkText,
                    formPage,
                    lang,
                    gartner,
                    seoPath,
                    breadcrumbs,
                    translations,
                    noFooter = false,
                    headerJustLogo = false,
                    whiteLogoOverride = false,
                    greenLogoOverride = false
    }) => {

    const state = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);

    const [headerVideo, setHeaderVideo] = useState(CURR_VID_INIT);
    const [headerVideoActive, setHeaderVideoActive] = useState(false);

    const { isMobile, isTablet } = useDeviceDetect();

    useEffect(() => {
        if(state.headerVideo.vidId !== headerVideo.vidId) {
            setHeaderVideo(state.headerVideo)
        }

        if (state.headerVideoActive !== headerVideoActive){
            setHeaderVideoActive(state.headerVideoActive)
        }

    }, [state]);

    const toggleHeaderVideoLightbox = () => dispatch({type: ACTION_TYPES.TOGGLE_HEADER_VIDEO});

    return (
  <StaticQuery
    query={graphql`
        query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        mainMenu: wpMenu(slug: {eq: "main-menu"}) {
            ...MainMenu
        }
        japaneseMainMenu: wpMenu(slug: {eq: "main-menu-primary-menu-japanese"}) {
            ...MainMenu
        }
        frenchMainMenu: wpMenu(slug: {eq: "main-menu-primary-menu-french"}) {
            ...MainMenu
        }
        germanMainMenu: wpMenu(slug: {eq: "main-menu-primary-menu-german"}) {
            ...MainMenu
        }
        footerMenu: wpMenu(slug: {eq: "footer-menu"}){
            ...FooterMenu
        }
        allWpMenuResource{
            edges {
                node {
                    title
                    databaseId
                    menuResourceResource {
                        menuResourceColumnTitle
                        menuResource {
                            ... on WpResource {
                                title
                                resourceFields {
                                    resourceLink
                                    resourceType
                                    resourceSmallImage {
                                        altText
                                        localFile {
                                            publicURL
                                        }
                                    }
                                    resourceButtonText
                                }
                            }
                        }
                    }
                }
            }
        }
      }
    `}
    render={data => (
      <div className={`${className} ${langClassMap[lang]}`} style={{position: 'relative'}}>
          <Helmet
              bodyAttributes={{
                  class: `page-${wpid}`
              }}
          >
              <script type="text/plan" className="optanon-category-2">{`
                  ${formPage ? 'setTimeout(function() {' : ''}
                  (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:151460,hjsv:5};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');

                    ${formPage ? '},2000);' : ''}
                  `}
              </script>
              <script type="application/ld+json">
                  {`
                    {
                        "@context": "https://schema.org",
                        "@graph": [{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "SASE",
                            "url": "https://www.catonetworks.com/sase/"
                        },{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "SD-WAN",
                            "url": "https://www.catonetworks.com/sd-wan/"
                        },{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "Cato Cloud",
                            "url": "https://www.catonetworks.com/cato-cloud/"
                        },{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "Use Cases",
                            "url": "https://www.catonetworks.com/solutions/"
                        },{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "Contact Us",
                            "url": "https://www.catonetworks.com/contact-us/"
                        },{
                            "@context": "https://schema.org",
                            "@type": "SiteNavigationElement",
                            "@id": "#table-of-contents",
                            "name": "Company",
                            "url": "https://www.catonetworks.com/company/"
                        }
                     ]
                    }
                  `}
              </script>
          </Helmet>
        <Header
            whiteMenuPage={whiteMenu}
            transparentMenu={transparentMenu}
            darkText={darkText}
            resources={data.allWpMenuResource.edges}
            items={data[menuMapping[lang]]}
            lang={lang || "en_US"}
            siteTitle={data.site.siteMetadata.title}
            justLogo={headerJustLogo}
            whiteLogoOverride={whiteLogoOverride}
            greenLogoOverride={greenLogoOverride}
        />
          <SEO
            yoastData={yoastData || null}
            seoTitle={seoTitle || null}
            title={`${title} `}
            keywords={yoastData && yoastData.focuskw ? [...yoastData.focuskw] : ['SD-WAN', 'Cato Networks', 'MPLS']}
            seoPath={seoPath}
            breadcrumbs={breadcrumbs || null}
            translations={translations}
          />
        <div>
            <main>
                {children}
            </main>
            {/*{*/}
                {/*(stickyBanner) &&*/}
                {/*<StickyBannerCTA {...{stickyBanner}}/>*/}
            {/*}*/}
            {/*{youtube &&*/}
            {/*<YouTubeSection*/}
                {/*{...youtube}*/}
            {/*/>*/}
            {/*}*/}
            {/*{coolInsights &&*/}
            {/*<InsightsBanner*/}
                {/*{...coolInsights}*/}
            {/*/>*/}
            {/*}*/}
            {
                gartner && gartner.show && gartner.type === 'footer' &&
                    <GartnerStrip
                        lang={lang}
                        {...gartner}
                    />
            }
            {!noFooter && <Footer items={data.footerMenu} mobile={isMobile} tablet={isTablet} lang={lang}/>}
        </div>
          <VideoLightboxWrap
            active={headerVideoActive}
            setActive={toggleHeaderVideoLightbox}
            currVid={headerVideo}
          />
          {doCleanImages()}
      </div>
    )}
  />
)};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    whiteMenu: true,
    transparentMenu: false,
    darkText: false,
    formPage: false,
    gartner: {show: false, klass: 'green'}
};

export default Layout
