import React, {Component} from 'react';
import {Link} from 'gatsby';
import CatoButton from '../../cato-button';
import CatoLink from '../../cato-link';
import ResourceLink from '../../resource-link';
import HamburgerLink from "../../hamburger-link";
import MobileMenu from "../../mobile-menu";
import SASEResource from '../../../assets/images/SASE mega menu asset.png';
import {GTag, LANG, CONTACT_MAP, ACTION_TYPES, LANG_PATH_PREFIX} from '../../../common/consts.js';
import {youTubeGetID} from '../../../common/utils';
import {GlobalDispatchContext} from "../../../context/GlobalContextProvider"
import WhiteLogo from '../../../assets/images/CATO LOGO 2019 white -svg.svg';
import Logo from '../../../assets/images/CATO LOGO 2019-svg.svg';


const LOGIN_LABEL_MAP = {
    [LANG.ENG]: 'Login',
    [LANG.JP]: 'ログインする'
}


class HeaderDesktop extends Component {
    constructor(props) {
        super(props)

        this.state = {
            path: ''
        }
    }

    static contextType = GlobalDispatchContext;

    componentDidMount() {
        this.setState({...this.state, path: `${window.location.pathname}`})
    }

    generateLinks = () => {
        const {path} = this.state;

        return (this.props.links.map((item, i) => (
            <li
                className={`header-menu-item ${item.subMenu ? 'with-sub' : ''} ${item.klass}`}
                onMouseEnter={item.subMenu ? this.handleOpenSubMenu : this.closeSubMenu}
                data-placement={i}
                id={`header-${item.type}`}
                key={`menu-link-${i}`}>
                    <CatoLink
                        path={item.path}
                        className={`${this.props.subMenuToShow === item.type ? 'hover' : ''} ${item.path.includes(path) && (path !== LANG_PATH_PREFIX[this.props.lang] && path !== `${LANG_PATH_PREFIX[this.props.lang]}/`)? 'active' : ''}`}
                    >
                        {item.title}
                    </CatoLink>
            </li>
        )))
    };

    handleOpenSubMenu = (e) => this.props.handleOpenSubMenu(e);

    setHoveredSubMenu = (set) => this.props.setHoveredSubMenu(set);

    toggleResourceVideo = (video) => {
        const dispatch = this.context;

        dispatch({type: ACTION_TYPES.CHOOSE_HEADER_VIDEO, payload: video});
        dispatch({type: ACTION_TYPES.TOGGLE_HEADER_VIDEO});

    }

    generateSubMenu = () => {
        const {links, subMenuToShow, placement, left} = this.props;
        const subMenuType = subMenuToShow;

        const listObj = links.find(item => item.type === subMenuType);
        const subMenu = listObj.subMenu;

        const resource = this.props.resources.find(item => item.title === listObj.title);

        const resourceHeadline = resource.menuResourceResource.menuResourceColumnTitle ? resource.menuResourceResource.menuResourceColumnTitle : null;

        const resourceObj = resource ? resource.menuResourceResource.menuResource.map(item => ({
            bold: item.resourceFields.resourceType,
            light: subMenuType === 'resources' ? 'The Network for the Digital Business Starts with the SASE' : item.title,
            buttonLabel: item.resourceFields.resourceButtonText,
            link: {
                ext: item.resourceFields.resourceLink
            },
            video: item.resourceFields.resourceLink.includes('youtube') || item.resourceFields.resourceLink.includes('youtu.be') ? {
                    vidId: youTubeGetID(item.resourceFields.resourceLink),
                    title: item.title,
                    subTitle: null,
                    path: null,
                    buttonLabel: null
                } : null,
            img: <img src={item.resourceFields.resourceSmallImage.localFile.publicURL} alt="Resource"/>


        })) : null;

        const subMenuLinks = subMenu[0].category
            ?
            this.generateSubCategories(subMenu)
            :
            <ul className={`submenu-links hide-bullets-style ${listObj.style || ''}`}>
                {subMenu.map((item, i) =>
                    <li className={`submenu-item ${item.klass}`} key={`submenu-item-${i}`}><CatoLink path={item.path}>{item.title}</CatoLink></li>)}
            </ul>;

        return (
            <div
                className={`header-sub-menu ${listObj.style ? listObj.style : ''}${resourceObj ? ' with-resource' : ''}`}
                onMouseEnter={()=>this.setHoveredSubMenu(true)}
                onMouseLeave={this.closeSubMenu}
                style={ left ?
                    {left: `${left - 75}px`, right: 'auto'}
                    : null
                }
            >
                <div className={`links ${listObj.style ? listObj.style : ''}`}>
                    {subMenuLinks}
                </div>
                {resourceObj &&
                <div className={`resource ${resourceObj.length === 1 ? 'single' : 'multi'}`}>
                    {resourceHeadline && <h2>{resourceHeadline}</h2>}
                    {
                        resourceObj.map( (obj, i) => (<ResourceLink
                            key={`resource-link-${i}`}
                            {...obj}
                            type={resourceObj.length === 1 ? 'single' : 'multi'}
                            sideAlign={listObj.style === 'two-column'}
                            onClick={obj.video !== null ? this.toggleResourceVideo : null}
                        />))
                    }
                </div>
                }
            </div>
        )
    }

    closeSubMenu = () => this.props.closeSubMenu();

    generateSubCategories = (menu) => {
        return (
            <ul className="submenu-links hide-bullets-style categories">
                {menu.map((cat, i) => {
                    return <li className="submenu-category" key={`submenu-category-${i}`}>
                        <div className="category-name">{cat.displayName}</div>
                        <ul className="category-links hide-bullets-style">
                            {cat.items && cat.items.map((link, j) => (
                                <li key={`category-link-${j}`} className={`${link.klass}`}><CatoLink path={link.path}>{link.title}</CatoLink></li>
                            ))}
                        </ul>
                    </li>
                })}
            </ul>
        )
    }

    contactButtonEvent = () => GTag({category: 'Contact Us', action: 'Button Click', label: CONTACT_MAP[this.props.lang].path})

    render() {
        const {showSubMenu, lang, justLogo, whiteLogoOverride, whiteLogo, greenLogoOverride} = this.props;
        const links = this.props.links ? this.generateLinks() : null;
        const submenu = showSubMenu ? this.generateSubMenu() : null;
        return (
            <div className="header-desktop">
                <Link className={`logo ${whiteLogoOverride ? 'white-logo' : ''}`} to={LANG_PATH_PREFIX[lang] || LANG_PATH_PREFIX[LANG.ENG]}>
                    <img src={whiteLogo || whiteLogoOverride ? WhiteLogo : Logo} style={{marginBottom: '0'}} alt="Cato Networks SASE Cloud Platform"/>
                </Link>
                {!justLogo &&
                    <div className="menu-items">
                        <ul className={'hide-bullets-style'}>
                            {links}
                            <li>
                                <Link
                                    onClick={this.contactButtonEvent}
                                    className={`${this.props.buttonColor} header cato-button`}
                                    to={CONTACT_MAP[lang in CONTACT_MAP ? lang : LANG.ENG].path}
                                >
                                    {CONTACT_MAP[lang in CONTACT_MAP ? lang : LANG.ENG].label}
                                </Link>
                            </li>
                            <HamburgerLink showOnOpen={(closeMenu) => <MobileMenu onContactClick={this.contactButtonEvent} items={this.props.links} lang={lang} closeMenu={closeMenu}/>}/>
                            <li className="header-menu-item"><a href="https://cc2.catonetworks.com/login">{LOGIN_LABEL_MAP[lang]}</a></li>
                        </ul>
                        {submenu}
                    </div>
                }
            </div>
        )
    }
}

export default HeaderDesktop;