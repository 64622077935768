import axios from 'axios';
import {isPlainObject} from 'lodash';
// import {decodeXML} from 'entities';
// import {decode} from 'he'
import {HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT, ENTITIES_MAP} from "./consts";

// const entities = new XmlEntities();


export const pageVisibility = () => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    return { hidden, visibilityChange };
};

export const decimals = (a) => {
    if (!isFinite(a)) return 0;
    let e = 1, p = 0;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }
    return p;
}

export const videoSectionMapper = (headline, videos) => {

    return {
        headline,
        videos: videos.map(video => ({
            id: video.videoId,
            title: video.titleText,
            summary: video.summary,
            img: video.videoImg ? video.videoImg : null
        }))
    }
};

export const resourcesTrioMapper = (headline, items) => {
    return {
        headline,
        data: items.map(item => ({
            type: item.resourceFields.resourceType || "",
            title: item.title || "",
            summary: item.resourceFields.resourceShortDescription || "",
            image: item.resourceFields.resourceSmallImage ? item.resourceFields.resourceSmallImage : null,
            link: {
                text: item.resourceFields.resourceButtonText,
                path: item.resourceFields.resourceLink
            }
    }))}
};

/**
 * For promo footer
 */
export const mapResourcesList = (items) => {
    return items.map((item) => {
        return ({
            title: item.title,
            summary: item.resourceFields.resourceLongDescription,
            link: item.resourceFields.resourceLink,
            buttonLabel: item.resourceFields.resourceButtonText,
            img: item.resourceFields.resourceSmallImage ? item.resourceFields.resourceSmallImage.localFile : null
        })
    })
};

export const getRevision = async (data, type) => {
    const imgType = ['png', 'jpg', 'svg'];

    const revisionObjFix = (obj) => Object.keys(obj).forEach(item => {
            if (typeof obj[item] === 'string') {
                const split =  obj[item].split('.');
                if(imgType.indexOf(split[split.length - 1]) > -1){
                    const imgUrl = obj[item];
                    obj[item] = {
                        localFile: {
                            publicURL: imgUrl
                        }
                    }
                }
            } else if (Array.isArray(obj[item]) || isPlainObject(obj[item])) {
                revisionObjFix(obj[item]);
            }
        });

    const url = process.env.GATSBY_WP_URL;

    const auth = {
        username: data.user,
        password: data.token
    };


    const response = await axios.get(
        `https://${url}/wp-json/wp/v2/${type}/${data.wordpressId}/revisions`,
        {
            auth,
            crossDomain: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    if(response){
        const newData = response.data[0];
        revisionObjFix(newData);
        return {wpPage: {...newData}};

    }
}

const _createEscaper = (map) => {
    const escaper = function(match) {
        return map[match];
    };
    // Regexes for identifying a key that needs to be escaped.
    const source = '(?:' + Object.keys(map).join('|') + ')';
    const testRegexp = RegExp(source);
    const replaceRegexp = RegExp(source, 'g');
    return function(string) {
        string = string == null ? '' : '' + string;
        return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
    };
}

export const htmlDecode = input => {
    const decode = _createEscaper(ENTITIES_MAP);
    return decode(input);
// export const htmlDecode = input => decodeXML(input);
}

export const mapGartner = (wpPage, klass) => {
    const {includeGartner} = wpPage;
    if(!wpPage.includeGartner) {
        return null;
    }

    return ({klass: klass ? klass : 'green', show: includeGartner.gartnerInclude, type: includeGartner.gartnerType});
};

export const scrollEventClassControl = (state, handleStateCallback ) => {
    if (!state.isScrolled && window.scrollY > (HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT / 2))
        handleStateCallback({...state, isScrolled: true});
    if (state.isScrolled && window.scrollY <= (HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT / 2))
        handleStateCallback({state, isScrolled: false});
}

export const hashRedirect = ({hash, newHash}) => {
    if(typeof window !== 'undefined' && window.location.hash.substring(1) === hash){
        window.location.replace(`${window.location.pathname}#${newHash}`)
    }
}

/**
 *
 * @param obj
 */
export const removeEmpty = obj =>
    Object.keys(obj)
        .filter(k => obj[k] != null)
        .reduce(
            (newObj, k) =>
                typeof obj[k] === "object"
                    ? { ...newObj, [k]: removeEmpty(obj[k]) }
                    : { ...newObj, [k]: obj[k] },
            {}
        );


export const canUseWebP = () => {
    if (typeof window !== 'undefined') {
        const elem = document.createElement('canvas');

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }

        // very old browser like IE 8, canvas not supported
        return false;
    }
}

export const youTubeGetID = (url) => {
    const [a, , b] = url
        .replace(/(>|<)/gi, '')
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
        return b.split(/[^0-9a-z_-]/i)[0];
    } else {
        return a;
    }
};

export const flatListToHierarchical = (
    data = [],
    {idKey='key',parentKey='parentId',childrenKey='children'} = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
        const newItem = {...item};
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId
            ? (
                childrenOf[parentId] = childrenOf[parentId] || []
            ).push(newItem)
            : tree.push(newItem);
    });
    return tree;
};

export const mapStickyBanner = (wpPage) => {
    if (!wpPage.stickyBanner.showStickyBanner) {
        return false
    }

    return ({
        label: wpPage.stickyBanner.stickyBannerLabel,
        link: wpPage.stickyBanner.stickyBannerLink,
        goldenLabel: wpPage.stickyBanner.goldenButtonText,
        goldenLink: wpPage.stickyBanner.goldenButtonLink,
    })
};

export const mapCoolInsights = (wpPage) => {
    const {resourcesTrioFooter} = wpPage;
    if(!resourcesTrioFooter || !resourcesTrioFooter.resourcesTrioResources) {
        return null
    }

    return resourcesTrioMapper( resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources);

}

export const stripHtml = (html) => {
    if(typeof window === 'undefined') return html;

    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

export const lazyLoadable = ({cb, fallback=null, rootMargin="0px"}) => {
    if(typeof window === 'undefined' || !fallback) return false;

    console.log('fallback: ', fallback);

    const node = document.createElement(`${fallback.type}`);
    node.classList.add(`${fallback.props.className}`);

    console.log('node: ', node);

    const observer = new IntersectionObserver(
        ([entry]) => {
            // Update our state when observer callback fires
            // setIntersecting(entry.isIntersecting);
        },
        {
            rootMargin,
        }
    );
}