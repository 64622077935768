import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby';
import CatoImg from '../cato-image';
import CatoLink from '../cato-link';
import FB from '../../assets/images/fb.png';
import LINKEDIN from '../../assets/images/IN.png';
import TWITTER from '../../assets/images/TWITTER.png';
import SOC from '../../assets/images/footer/SOC2.png';
import GDPR from '../../assets/images/footer/GDPR.png';
import ISO from '../../assets/images/footer/ISO.png';
import Logo from '../../assets/images/footer/Cato Logo.svg';
import {flatListToHierarchical} from '../../common/utils';
import {CONTACT_MAP, DEMO_MAP, LANG} from "../../common/consts";
import CarbonFootprint from '../../assets/images/footer/Carbon Neutral - footer icon with text.svg';
import Lazyload from 'react-lazyload'
import "./style.scss";

export const SocialLinks = () => (
    <ul className="social-links hide-bullets-style">
        <li><a href="https://www.facebook.com/CatoNetworks/" target="_blank" rel="noopener noreferrer"><img src={FB} alt=""/></a></li>
        <li><a href="https://linkedin.com/company/cato-networks" target="_blank" rel="noopener noreferrer"><img src={LINKEDIN} alt=""/></a></li>
        <li><a href="https://twitter.com/catonetworks" target="_blank" rel="noopener noreferrer"><img src={TWITTER} alt=""/></a></li>
    </ul>
);



const Footer = (props) => {
    const {mobile, tablet} = props;

    // const appendWidgetScript = () => {
    //     const script = document.querySelector('#gartner-widget-script');
    //
    //     document.body.appendChild(script);
    // }

    useEffect(() => {
        const isBlock = mobile || tablet;

        if(document.querySelector("#footer-widget")) {
            const widget = document.querySelector("#footer-widget");
            while (widget.firstChild) {
                widget.removeChild(widget.lastChild);
            }
        }

        // if(!document.querySelector("#gartner-widget-script-call")) {

            const script = document.createElement('script');
            script.setAttribute('id', 'gartner-widget-script-call')
            script.innerHTML = `
        if(!document.querySelector("#footer-widget div")){
            // setTimeout( () => {
            GartnerPI_Widget({
                size: "${isBlock ? "small" : "line"}",
                theme: "${isBlock ? "dark" : "light"}",
                sourcingLink: "",
                widget_id: "${isBlock ? "N2JiZDBlZWMtN2FjMi00ZTk2LWJiNzUtYmM0ZDZlMTFjMzE2" : "ODUxNDdhY2ItYWJiYi00ODQxLWEyODktY2M1MmU2NjFlZTNj"}",
                version: "2",
                container: document.querySelector("#footer-widget")
            })
            // }, 500)
        }
    `;

            script.async = true;

            const getScript = script;

        const handleMouseMove = () => document.body.appendChild(getScript);


        window.addEventListener('mousemove', handleMouseMove, {once: true});
        // }

        return () => {
            if(document.body.querySelector('#gartner-widget-script-call')) {
                document.body.removeChild(getScript);
            }
            window.removeEventListener('mousemove', handleMouseMove);
            const widget = document.querySelector("#footer-widget");
            while (widget.firstChild) {
                widget.removeChild(widget.lastChild);
            }
        }
    }, [mobile, tablet]);




    const mapLinks = (data) => data.map(item => ({
        title: item.label,
        path: item.url,
        submenu: item.childItems && item.childItems.nodes.length ? item.childItems.nodes.map(i => ({title: i.label, path: i.url, klass: i.className || null})) : null
    }));

    const genLink = (link) => <Link to={link.path}><span>{link.title}</span></Link>;

    const buildFooterLinks = (links, length) => {
        const nonWrapped = links.slice(0, length - 1);

        const nonWrappedLinks = nonWrapped.map((col, i) => {

            const link = col.notLink ?
                <div className="footer-column-header">{col.title}</div>
                : <Link className="footer-column-header" to={col.path}>{col.title}</Link>;

            return <li key={`footer-link-column-${i}`}>
                <div className="title">
                    {link}
                </div>
                {col.submenu ?
                    <ul className="footer-column-submenu hide-bullets-style">
                        {col.submenu.map((link, j) => {
                            return (
                                <li key={`footer-link-${j}`} className={link.klass ? `${link.klass}` : null}>
                                    {
                                        genLink(link)
                                    }
                                </li>
                            )
                        })}
                    </ul> : null}
            </li>
        });

        let companyList = links[links.length - 1];

        const tempWrappedOne = <li className="wrapped">
            <ul className={'hide-bullets-style'}>
                <li>
                    <div className="title">
                        <Link className="footer-column-header" to={companyList.path}>{companyList.title}</Link>
                    </div>
                    {companyList.submenu ?
                        <ul className="footer-column-submenu hide-bullets-style">
                            {companyList.submenu.map((link, j) => {
                                return (
                                    <li key={`footer-link-${j}`}>
                                        {
                                            genLink(link)
                                        }
                                    </li>
                                )
                            })}
                        </ul> : null}
                </li>
                <li><div className="title"><Link to="/customers">Customers</Link></div></li>
                <li>
                    <div className="title"><Link to="/partners">Partners</Link></div>
                    <ul className={'hide-bullets-style'}>
                        <li>
                            <Link className="green-rest" to="/partners#become-a-partner"><span>Become a Partner</span></Link>
                        </li>
                        <li>
                            <a className="green-rest" href="https://partners.catonetworks.com"><span>Partner Portal Login</span></a>
                        </li>
                    </ul>
                </li>
                <li><div className="title"><Link to="/blog">Blog</Link></div></li>
            </ul>
        </li>;

        const tempWrappedTwo = <li className="wrapped">
            <ul className={'hide-bullets-style'}>
                <li>
                    <div className="title"><Link to="/contact">Contact</Link></div>
                    <ul className={'hide-bullets-style'}>

                        <li><div><Link className="green-rest" to={CONTACT_MAP[props.lang] ? CONTACT_MAP[props.lang].path : CONTACT_MAP[LANG.ENG].path}><span>{CONTACT_MAP[props.lang] ? CONTACT_MAP[props.lang].label : CONTACT_MAP[LANG.ENG].label}</span></Link></div></li>
                        <li><Link className="green-rest" to={DEMO_MAP[props.lang] ? DEMO_MAP[props.lang].path : DEMO_MAP[LANG.ENG].path}><span>{DEMO_MAP[props.lang] ? DEMO_MAP[props.lang].label : DEMO_MAP[LANG.ENG].label}</span></Link></li>
                    </ul>
                </li>
                <li className="social">
                    <div className="title">Follow Us</div>
                    <SocialLinks />
                </li>
            </ul>
        </li>;

        return <ul className="footer-links hide-bullets-style">
            {nonWrappedLinks}
            {tempWrappedOne}
            {tempWrappedTwo}
        </ul>
    };

    const buildPolicy = () => {
        const year = new Date().getFullYear();
        return (
            <div className="bottom">
                All rights reserved Cato Networks {year} <span className="dot">•</span>
                <Link to="/privacypolicy">Privacy policy</Link> <span className="dot">•</span>
                <Link to="/security-compliance-and-privacy/">Security, Compliance and Privacy</Link> <span className="dot">•</span>
                <Link to="/cookie-notice/">Cookie Notice</Link>
                <Link to={"/carbon-footprint/"} className="carbon-footprint"><img src={CarbonFootprint} alt=""/></Link>
            </div>
        )
    };



    const heirchicalData = flatListToHierarchical(props.items.menuItems.nodes);
    const mappedLinks = mapLinks(heirchicalData);
    // const mappedLinks = mapLinks(TEMP_FOOTER);
    const links = buildFooterLinks(mappedLinks, mappedLinks.length);
    const bottom = buildPolicy();

    return (
        <footer className="cato-footer">
            <Lazyload height={53}>
                <img src={Logo} alt="Cato Networks" className={'logo'} height={53} width={394}/>
            </Lazyload>
            {(props.mobile || props.tablet) && <div id="footer-widget"/>}
            <div className="top">{links}</div>
            <div className="widget-and-compliance">
                {!props.mobile && !props.tablet && <div id="footer-widget"/>}
            <ul className="compliance">
                <li className="compliance-image">
                    <CatoLink
                        path={'/security-compliance-and-privacy/'}
                    >
                        <CatoImg
                            img={{publicURL: ISO, altText: 'ISO Compliant'}}
                            width={'201px'}
                            height={'57px'}
                            style={{aspectRatio: 'attr(width) / attr(height)', height: 'auto'}}
                        />
                    </CatoLink>
                </li>
                <li className="compliance-image">
                    <CatoLink
                        path={'/security-compliance-and-privacy/'}
                    >
                        <CatoImg
                            img={{publicURL: SOC, altText: 'SOC2 Compliant'}}
                            width={'209px'}
                            height={'57px'}
                            style={{aspectRatio: 'attr(width) / attr(height)', height: 'auto'}}
                        />
                    </CatoLink>
                </li>
                <li className="compliance-image">
                    <CatoLink
                        path={'/security-compliance-and-privacy/'}
                    >
                        <CatoImg
                            img={{publicURL: GDPR, altText: 'GDPR Compliant'}}
                            width={'209px'}
                            height={'58px'}
                            style={{aspectRatio: 'attr(width) / attr(height)', height: 'auto'}}
                        />
                    </CatoLink>
                </li>
            </ul>
            </div>
            {bottom}
        </footer>
    )
}

export default Footer;
