import React from 'react';
import PropTypes from 'prop-types';
import CatoLink from '../cato-link';
import './style.scss';

const CatoButton = (props) => {

    const {label, type, path, className, icon, name, style} = props;

    const onClick = () => props.onClick ? props.onClick({label, path, name}) : null;

    const onMouseEnter = () => props.onMouseEnter();

    const onMouseLeave = () => props.onMouseLeave();

    return (type === 'link' ?
        <CatoLink
            path={path}
            className={`cato-button ${className ? className : ''} ${icon ? 'icon': ''}`}
            onClick={onClick}
            onMouseEnter={props.onMouseEnter ? onMouseEnter : null}
            onMouseLeave={props.onMouseLeave ? onMouseLeave : null}
            style={style}
        >
            {icon ? <img alt={'button'} className="button-icon" src={icon}/> : null}
            {label}
        </CatoLink>
        :
        <button
            className={`cato-button ${className ? className : ''} ${icon ? 'icon': ''}`}
            onClick={onClick}
            onMouseEnter={props.onMouseEnter ? onMouseEnter : null}
            onMouseLeave={props.onMouseLeave ? onMouseLeave : null}
            style={style}
        >
            {icon ? <img alt={'button'} className="button-icon" src={icon}/> : null}
            {label}
        </button>)

}

CatoButton.propTypes = {
    type: PropTypes.oneOf(['link', 'button', 'none']),
    label: PropTypes.string,
    path: PropTypes.string,
    onClick: PropTypes.func,
    external: PropTypes.bool,
    name: PropTypes.string
    // icon: PropTypes.object
}

CatoButton.defaultProps = {
    type: 'link',
    external: false
}

export default CatoButton;