import React, {Component, Fragment} from 'react';
import './style.scss';

class HamburgerLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    render() {
        const {open} = this.state;
        const {showOnOpen} = this.props;
        return (
            <Fragment>
                <input className="menu-trigger hidden" id="togglenav" checked={open} onChange={() => this.setState({open: !open})} type="checkbox"/>
                <label className="burger-wrapper" htmlFor="togglenav">
                    <div className="hamburger"/>
                </label>
                {open ? showOnOpen(() => this.setState({open: false})) : null}
            </Fragment>
        )
    }
}

export default HamburgerLink