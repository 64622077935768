import React, {Component} from "react";
import PropTypes from "prop-types";
import {HEADER_Z_INDEX, HEADER_HEIGHT_DESKTOP_SCROLL_HEIGHT, GTag, ACTION_TYPES, LANG, IS_TABLET, IS_MOBILE} from "../../common/consts"
import {scrollEventClassControl, flatListToHierarchical} from '../../common/utils';
import HeaderDesktop from './header-desktop';
import {GlobalDispatchContext} from "../../context/GlobalContextProvider";
import './_style.scss';

let mountedScrollEvent = false;
let scrollEventHandler = () => {
};

const mountScrollEvent = () => {
    if (mountedScrollEvent) return;
    mountedScrollEvent = true;
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', () => scrollEventHandler());
    }
};

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isScrolled: false,
            showSubMenu: false,
            subMenuToShow: '',
            path: '',
            hoveredSubMenu: false,
            placement: 0,
            left: null
        };

        // DO NOT setState in each scroll event, it impacts performance :)
        // We check if the old state is the opposite of what it should be, only then we set the state
        scrollEventHandler = () => {
            scrollEventClassControl(this.state, (newState) => this.setState(newState));

            if (this.state.showSubMenu) {
                this.setState({...this.state, showSubMenu: false})
            }


        };
        mountScrollEvent();
    }

    static contextType = GlobalDispatchContext;

    componentDidMount() {
        const dispatch = this.context;
        dispatch({type: ACTION_TYPES.INIT_HEADER})
    }


    componentDidUpdate(prevProps, prevState) {

        const dispatch = this.context;
        if(prevState.isScrolled !== this.state.isScrolled) {
            dispatch({type: ACTION_TYPES.TOGGLE_HEADER_FIXED})
        }
    }

    handleOpenSubMenu = (e) => {
        const subMenu = e.target.parentElement.id.replace('header-', '');
        const placement = parseInt(e.target.parentElement.getAttribute('data-placement'), 10);
        const left = placement <= 1 ? e.target.getBoundingClientRect().left : null;

        this.setState({...this.state, showSubMenu: true, subMenuToShow: subMenu, placement, left})
    };

    closeSubMenu = () => {
        this.setState({...this.state, showSubMenu: false, subMenuToShow: '', hoveredSubMenu: false})
    };

    setHoveredSubMenu = (set) => this.setState({...this.state, hoveredSubMenu: set});

    mapMenuItems = () => {
        const items = flatListToHierarchical(this.props.items.menuItems.nodes);

        return items.map((item, i) => {
            let style = item.childItems.nodes.length && item.childItems.nodes[0].childItems.nodes.length ? 'categories' : i === 0 ? 'sase' : null;

            if(item.label === 'Partners'){
                style += ' partners'
            }

            const subMenu = item.childItems.nodes.length
            ?
                item.childItems.nodes[0].childItems.nodes.length > 0
                ?
                    item.childItems.nodes.map(sub => ({
                        displayName: sub.label,
                        category: sub.label,
                        klass: sub.cssClasses.join(' '),
                        items: sub.childItems.nodes.length ? sub.childItems.nodes.map(i => ({title: i.label, path: i.url, klass: i.cssClasses.join(' ')})) : null
                    }))
                    :
                item.childItems.nodes.map(sub => ({
                    title: sub.label,
                    path: sub.url,
                    klass: sub.cssClasses.join(' ')
                }))
                :
                null;

            return ({
                title: item.label,
                path: item.url,
                type: item.label,
                klass: item.classes,
                style,
                subMenu
            })
        })
    }

    render() {
        const {isScrolled, showSubMenu, subMenuToShow, hoveredSubMenu, placement, left} = this.state;
        const menuItems = this.props.items.menuItems.nodes ? this.mapMenuItems() : null;
        const shouldBeWhite = isScrolled || this.props.whiteMenuPage || this.props.transparentMenu;
        const resources = this.props.resources.map(node => ({...node.node}));


        const menu = <HeaderDesktop
            links={menuItems}
            resources={resources}
            buttonColor={'orange'}
            handleOpenSubMenu={this.handleOpenSubMenu}
            closeSubMenu={this.closeSubMenu}
            setHoveredSubMenu={this.setHoveredSubMenu}
            subMenuToShow={subMenuToShow}
            showSubMenu={showSubMenu}
            hoveredSubMenu={hoveredSubMenu}
            placement={placement}
            left={left}
            lang={this.props.lang}
            justLogo={this.props.justLogo}
            whiteLogoOverride={this.props.whiteLogoOverride}
            greenLogoOverride={this.props.greenLogoOverride}
            whiteLogo={!shouldBeWhite}
        />;

        return (
            <header
                className={`site-header ${shouldBeWhite ? 'white' : ''} ${this.props.transparentMenu && !isScrolled ? 'transparent' : ''} ${this.props.darkText ? 'dark-text' : ''} ${isScrolled ? 'down' : ''}`}
                style={{
                    position: 'fixed',
                    width: '100%',
                    zIndex: HEADER_Z_INDEX,
                    top: '0'
                }}
            >
                {menu}
            </header>
        )
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    whiteMenuPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;

// export const query = graphql`
//     query {
//         logoWhite: file(relativePath: { eq: "logo.png"}) {
//         childImageSharp {
//           fixed(width: 152, height: 63){
//             ...GatsbyImageSharpFixed
//           }
//         }
//     }
//     }
// `