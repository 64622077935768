import React, {useState, useEffect} from 'react';
import X from './x.svg';
import './style.scss';

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value + 1);
}

function disableScrolling() {
    if(typeof window === 'undefined') return false;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    window.onscroll = () => window.scrollTo(scrollLeft, scrollTop);
}

function enableScrolling() {
    window.onscroll = '';
}

const Lightbox = ({children, active, setActive, className}) => {

    const forceUpdate = useForceUpdate();

    const handleActive = (e) => {
        if(e.target.classList.contains('lightbox-wrapper') || e.target.classList.contains('close')) {
            e.stopPropagation();
            setActive();
            setTimeout(() => {
                forceUpdate();
            }, 100);
        }
    }

    useEffect(() => {
        if(active) disableScrolling();
        else if (!active) enableScrolling();
        return () => enableScrolling()
    }, [active]);

    return (
        <div className={`lightbox-wrapper ${active ? 'active' : ''} ${className || ''}`} onClick={handleActive}>
            <div className="content">
                <div className="close" onClick={handleActive} ><img src={X} alt="" style={{pointerEvents: 'none'}}/></div>
                {children}
            </div>
        </div>
    )

}

export default Lightbox;
